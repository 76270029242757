<template>
	<div id="wrap">
		<top page="industry" @search="handleSearch" :showSearchButton="true" />
		<div class="mobile-wrap">
			<div class="tab">
				<div class="mainBox">
					<a 
						class="on" 
						v-for="category in categoryList" 
						:key="category.id"
						@click="goAnchor(`#div_${category.id}`)"
					>
						{{category.value}}
					</a>
				</div>
			</div>
			<focus-pic category="行业推荐焦点图" />
			<div class="module-1" v-for="category in categoryList" :key="category.id" :id="`div_${category.id}`">
				<div class="mainBox wow fadeInUp" data-wow-offset="0" data-wow-delay="0.1s" v-if="category.industryList.length > 0">
					<div class="module-title">
						<h2>{{category.value}}</h2>
					</div>
				</div>
				<div class="mainBox">
					<div class="list-box">
						<div 
							class="list wow fadeInUp" 
							data-wow-offset="0" 
							data-wow-delay="0.1s"
							v-for="item in category.industryList"
							:key="item.id"
							@click="handleShowIndustryWindow(item)"
						>
							<div class="con">
								<h1>{{item.shopName}}</h1>
								<p>经营类目：{{item.businessCategory}}</p>
								<div class="link">
									<div class="weixin" v-if="item.wechat !== ''"><span></span>{{item.wechat}}</div>
									<div class="taobao" v-if="item.taobao !== ''"><span></span>{{item.taobao}}</div>
									<div class="douyin" v-if="item.douyin !== ''"><span></span>{{item.douyin}}</div>
									<div class="work-time">{{item.workTime}}</div>
								</div>
							</div>
							<div class="pic">
								<img :src="item.thumbnail.absolutePath"  />
							</div>
						</div>
					</div>
				</div>
			</div>
			<!-- module-2 -->
			<div class="module-3">
				<div class="mainBox">
				</div>
			</div>
			<!-- module-3 -->
		</div>
		<bottom />
		<div class="works-window" style="display: none;">
			<div class="window-con">
				<div class="windos-close" @click="handleCloseIndustryWindow"><span class="iconfont iconclose"></span></div>
				<div class="list">
					<div class="pic">
						<img :src="selectedIndustry.thumbnail.absolutePath" />
					</div>
					<div class="con">
						<h1>{{selectedIndustry.shopName}}</h1>
						<p>经营类目：{{selectedIndustry.businessCategory}}</p>
						<div class="link">
							<div class="weixin" v-if="selectedIndustry.wechat !== ''"><span></span>{{selectedIndustry.wechat}}</div>
							<div class="taobao" v-if="selectedIndustry.taobao !== ''"><span></span>{{selectedIndustry.taobao}}</div>
							<div class="douyin" v-if="selectedIndustry.douyin !== ''"><span></span>{{selectedIndustry.douyin}}</div>
							<div class="work-time">{{selectedIndustry.workTime}}</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<side />
	</div>
</template>

<script>
import top from "./components/top.vue"
import bottom from "./components/bottom.vue"
import focusPic from "./components/focusPic.vue"
import side from "./components/side.vue"
import { wxShare } from "@/utils/share.js";
export default {
	page: {
		title: "商家推荐"
	},
	data() {
		return {
			categoryList: [],
			selectedIndustry: {
				shopName: '',
				businessCategory: '',
				thumbnail: {
					absolutePath: ''
				}
			},
			search: {
				shopName: ''
			}
		}
	},
	components: {
		top,
		bottom,
		focusPic,
		side
	},
	methods: {
		handleWxShare() {
			let url = window.location.href

			this.$api.wechat.mpwechat.getJsapiSignature(url)
			.then(response => {
				if(response.code === '0') {

					let browser = window.navigator.userAgent.toLowerCase();	

					// 非微信浏览器不执行分享代码
					if (browser.match(/MicroMessenger/i) != 'micromessenger') {
						return;
					} 	

					//微信加签
                    let obj = {
                        appId: response.data.appId,
                        nonceStr: response.data.nonceStr,
                        signature: response.data.signature,
                        timestamp: response.data.timestamp,
                        jsApiList: ['updateAppMessageShareData', 'updateTimelineShareData']
                    };


                    // var shareWxLink = encodeURIComponent(url);加密
                    let shareData = {
                        title: 'C-FLROAL年度认证商家推荐名单', // 分享标题
                        desc: '360度严选、产品亲测、服务达标；内涵数家商家精准联系方式。',
                        link: response.data.url,
                        imgUrl: 'http://www.c-floral.net/images/share/logo-03.jpg' // 分享图标
                    };

					//引用
                    wxShare(obj, shareData);
				}
				else {
					this.$message.info(response.message)
				}
			})
		},
		goAnchor (selector) {
			document.querySelector(selector).scrollIntoView({
				behavior:"smooth"
			})
		},
		handleFindAllIndustry() {
			this.$api.commend.industry.findAll(this.search.shopName)
			.then(response => {
				if(response.code === '0') {
					this.categoryList = response.data
					this.$nextTick(() => {
						new this.$wow.WOW().init()
					})
				}
				else {
					this.$message.error(response.message)
				}
			})
		},
		handleShowIndustryWindow(industry) {
			this.selectedIndustry = {}
			this.selectedIndustry = industry
			$('.works-window').fadeToggle('');
		},
		handleCloseIndustryWindow() {
			$('.works-window').fadeOut('');
		},
		handleSearch(keywords) {
			this.search.shopName = keywords;
			this.handleFindAllIndustry()
		}
	},
	mounted() {
		this.handleWxShare()
		this.handleFindAllIndustry()
	}
}
</script>

<style src="../style/css/industry.css" scoped></style>
<style scoped>
</style>